import React, { useRef, useState } from 'react';
import {
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  Platform,
} from 'react-native';
import { Ionicons, AntDesign } from '@expo/vector-icons';
import styled from 'styled-components';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Divider } from '@rneui/themed';
import * as DocumentPicker from 'expo-document-picker';

import Card from '../../presentational/Card';
import Input from '../../presentational/Input';
import Button from '../Button';
import Text from '../../presentational/Text';

import Gap from '../../presentational/Gap';
import Image from '../../presentational/Image';
import Switch from '../../presentational/Switch';
import List from '../List';
import CustomModal from '../Modal';

import { remToDp, vhToDp, vwToDp } from '../../../utilities/general';
import Col from '../../presentational/Col';
import Row from '../../presentational/Row';
import Icon from '../../presentational/Icon';
import { Camera } from 'expo-camera';
import moment from 'moment';
import Carousel, { Pagination } from 'react-native-reanimated-carousel';
import { useSharedValue } from 'react-native-reanimated';
import RemoveToolImageButton from '../Button/RemoveToolImageButton';

const ColSwitch = styled(Col)`
  padding-left: ${remToDp(1.5)}px;
`;

const AddToolStepForm = ({ ...props }) => {
  const progress = useSharedValue(0);
  const ref = useRef(null);
  const [currentStep, setCurrentStep] = useState(1);

  const openScanner = async () => {
    const { status } = await Camera.requestCameraPermissionsAsync();
    setCameraPermissionAction(status === 'granted');
    setScannerOpenAction(true);
  };

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const {
    tenant,
    tools: {
      input,
      nextCalibrationDateCalendarVisible,
      nextPMICalendarVisible,
      isEditable,
      oemStatusList,
      loadTestDatePickerActive,
      pressureTestCalendarVisible,
      cyclicTestCalendarVisible,
      functionalTestCalendarVisible,
    },
    auth,
    storageLocation,
    status: { placeholder },
    toolStatus: { searchResults },
    location,
    extraDocuments,
    document,
    documents,
    inspections,
    documentLoading,
    documentsToAdd,
  } = useStoreState((state) => state);

  const {
    setToolsInputAction,
    setNextCalibrationCalendarVisible,
    setNextPMICalendarVisible,
    upsertToolsThunk,
    setExtraDocumentListAction,
    upsertExtraDocumentThunk,
    setScannerOpenAction,
    setCameraPermissionAction,
    setLoadTestDatePickerActiveAction,
    setDocumentListAction,
    setAlertThunk,
    setInspectionsInputAction,
    setInspectionsEditAction,
    setInspectionsShowModalAction,
    upsertInspectionsThunk,
    setInspectionsListAction,
    setPressureTestCalendarVisible,
    setCyclicTestCalendarVisible,
    setFunctionalTestCalendarVisible,
  } = useStoreActions((actions) => actions);

  const handleKeyPress = ({ nativeEvent }) => {
    if (nativeEvent.key === 'Tab') {
      setCurrentStep(currentStep + 1);
    }
  };

  const onPressPagination = (index) => {
    console.log(progress);
    ref.current?.scrollTo({
      count: index - progress.value,
      animated: true,
    });
  };

  const renderFormStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            <Row>
              <Col xs={12} lg={6}>
                <Row>
                  <Col>
                    <Input
                      placeholder='Type in Part No.'
                      label='Part No.'
                      value={input.partNumber}
                      onChangeText={async (partNumber) =>
                        setToolsInputAction({ partNumber })
                      }
                      editable={isEditable}
                      required
                    />
                  </Col>
                  <Col>
                    <Input
                      placeholder='Type in Nomenclature'
                      label='Nomenclature'
                      value={input.nomenclature}
                      onChangeText={(nomenclature) =>
                        setToolsInputAction({ nomenclature })
                      }
                      editable={isEditable}
                      required
                    />
                  </Col>
                  <Col>
                    <Input
                      placeholder='Type in Serial No.'
                      label='Serial No.'
                      value={input.serialNumber}
                      onChangeText={(serialNumber) =>
                        setToolsInputAction({ serialNumber })
                      }
                      editable={isEditable}
                      required
                      onKeyPress={handleKeyPress}
                    />
                  </Col>
                  <Col>
                    <Input
                      placeholder='Type in Alternate Identifier'
                      label='Alternate Identifier'
                      value={input.alternativetoolid}
                      onChangeText={(alternativetoolid) =>
                        setToolsInputAction({ alternativetoolid })
                      }
                      editable={isEditable}
                      required
                      onKeyPress={handleKeyPress}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={12} lg={6}>
                <Row>
                  <Col xs={12} md={12} style={{ alignItems: 'center' }}>
                    <Carousel
                      ref={ref}
                      width={vwToDp(20)}
                      height={vhToDp(33)}
                      style={{ border: '1px solid black' }}
                      data={
                        documentsToAdd.length === 0
                          ? [placeholder]
                          : documentsToAdd
                      }
                      onProgressChange={progress}
                      renderItem={({ item }) => (
                        <Image
                          width={vwToDp(20)}
                          height={vhToDp(33)}
                          uri={item?.body}
                        />
                      )}
                    ></Carousel>
                    <Pagination.Basic
                      progress={progress}
                      data={documentsToAdd}
                      dotStyle={{
                        backgroundColor: 'rgba(0,0,0,0.2)',
                        borderRadius: 50,
                      }}
                      containerStyle={{ gap: 5, marginTop: 10 }}
                      onPress={onPressPagination}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={4}>
                    <Button.UploadToolImage setIndex={ref.current?.scrollTo} />
                  </Col>
                  <Col xs={12} md={4}>
                    <RemoveToolImageButton
                      getIndex={ref.current?.getCurrentIndex}
                      isEdit={!isEditable}
                      setIndex={ref.current?.scrollTo}
                    />
                  </Col>
                  <Col xs={12} md={4}>
                    <Button.RotateImage
                      isEdit={!document.notes}
                      getIndex={ref.current?.getCurrentIndex}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Button fullWidth title='Next' onPress={handleNextStep} />
              </Col>
              <Col xs={12} md={6}>
                <Button
                  fullWidth
                  title='Save'
                  disabled={
                    input.partNumber === '' &&
                    input.nomenclature === '' &&
                    input.serialNumber === ''
                  }
                  onPress={handleSubmit}
                />
              </Col>
            </Row>
          </>
        );
      case 2:
        return (
          <>
            <Row>
              <Col xs={12} md={6}>
                <Input
                  placeholder='Type in Facility Location'
                  label='Facility Location'
                  onSelect={(item) =>
                    setToolsInputAction({
                      tenantLocation: item.id,
                      storageLocationId: '',
                    })
                  }
                  selectedItem={(item) => item.name}
                  rowTextForSelection={(item) => item.name}
                  dropdown
                  dropdownChoices={location.searchResults
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .filter((item) => {
                      return item.active !== false;
                    })}
                  value={
                    location.searchResults.find(
                      (location) => location.id === input.tenantLocation
                    )?.name || ''
                  }
                  editable={isEditable}
                  onKeyPress={handleKeyPress}
                />
              </Col>
              <Col xs={12} md={6}>
                {input.tenantLocation !== 0 && (
                  <Input
                    placeholder='Type in Storage Location'
                    label='Storage Location'
                    value={
                      storageLocation.searchResults.find(
                        (location) => location.id === input.storageLocationId
                      )?.name || ''
                    }
                    onSelect={(storageLocation) =>
                      setToolsInputAction({
                        storageLocationId: storageLocation.id,
                      })
                    }
                    selectedItem={(item) => item.name}
                    rowTextForSelection={(item) => item.name}
                    dropdown
                    dropdownChoices={storageLocation.searchResults
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .filter((item) => {
                        return (
                          item.active !== false &&
                          input.tenantLocation === item.tenantlocationid
                        );
                      })}
                    editable={isEditable}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={4}>
                <Button
                  title='Previous'
                  fullWidth
                  onPress={handlePreviousStep}
                />
              </Col>

              <Col xs={12} md={4}>
                <Button fullWidth title='Next' onPress={handleNextStep} />
              </Col>
              <Col xs={12} md={4}>
                <Button
                  fullWidth
                  title='Save'
                  disabled={
                    input.partNumber === '' &&
                    input.nomenclature === '' &&
                    input.serialNumber === ''
                  }
                  onPress={handleSubmit}
                />
              </Col>
            </Row>
          </>
        );
      case 3:
        return (
          <>
            <Row>
              <Col xs={12} md={3}>
                <Input
                  placeholder='Type in Engine'
                  label='Engine'
                  value={input.engine}
                  onChangeText={(engine) => setToolsInputAction({ engine })}
                  editable={isEditable}
                />
              </Col>
              <Col xs={12} md={3}>
                <Input
                  placeholder='Type in Model'
                  label='Model'
                  value={input.model}
                  onChangeText={(model) => setToolsInputAction({ model })}
                  editable={isEditable}
                />
              </Col>
              <Col xs={12} md={3}>
                <Input
                  placeholder='Type in Weight'
                  label='Weight'
                  value={input.weight}
                  onChangeText={(weight) => setToolsInputAction({ weight })}
                  editable={isEditable}
                />
              </Col>
              <Col xs={12} md={3}>
                <Input
                  placeholder='Type in Size'
                  label='Size'
                  value={input.size}
                  onChangeText={(size) => setToolsInputAction({ size })}
                  editable={isEditable}
                />
              </Col>
              <Col xs={12} md={3}>
                <Input
                  placeholder='Recommended Storage Location'
                  label='Location'
                  value={input.recommendedstoragelocation}
                  onChangeText={(recommendedstoragelocation) =>
                    setToolsInputAction({ recommendedstoragelocation })
                  }
                  editable={isEditable}
                />
              </Col>
              <Col xs={12} md={3}>
                <Input
                  placeholder='Type in Phase'
                  label='Phase'
                  value={input.phase}
                  onChangeText={(phase) => setToolsInputAction({ phase })}
                  editable={isEditable}
                />
              </Col>
              <Col xs={12} md={3}>
                <Input
                  placeholder='Type in MFG Revision'
                  label='MFG Revision'
                  value={input.currentRevision}
                  onChangeText={(currentRevision) =>
                    setToolsInputAction({ currentRevision })
                  }
                  editable={isEditable}
                />
              </Col>
              <Col xs={12} md={3}>
                <Input
                  placeholder='Type in Batch Numbers'
                  label='Batch Numbers'
                  value={input.CFMId}
                  onChangeText={(CFMId) => setToolsInputAction({ CFMId })}
                  editable={isEditable}
                />
              </Col>
              <Col xs={12} md={3}>
                <Input
                  placeholder='Type in Barcode Scan ID'
                  label='Barcode Scan ID'
                  value={input.barcodescanid}
                  onChangeText={(barcodescanid) =>
                    setToolsInputAction({ barcodescanid })
                  }
                  editable={isEditable}
                  rightIcon={
                    <>
                      {Platform.OS == 'ios' ? (
                        <TouchableOpacity
                          onPress={() => {
                            openScanner();
                          }}
                        >
                          <Icon
                            Component={AntDesign}
                            name='barcode'
                            size={20}
                          />
                        </TouchableOpacity>
                      ) : null}
                    </>
                  }
                />
              </Col>
              <Col xs={12} md={3}>
                <Input
                  placeholder='Type in Manufacturing Case Code'
                  label='Manufacturing Case Code'
                  value={input.manufacturerCageCode}
                  onChangeText={(manufacturerCageCode) =>
                    setToolsInputAction({ manufacturerCageCode })
                  }
                  editable={isEditable}
                />
              </Col>
            </Row>
            <Col>
              <Input
                placeholder='Type in Notes'
                label='Notes'
                value={input.notes}
                onChangeText={(notes) => {
                  setToolsInputAction({ notes });
                }}
                editable={isEditable}
                onKeyPress={handleKeyPress}
                multiline
                style={{ height: 200 }}
              />
            </Col>
            <Row>
              <Col xs={12} md={4}>
                <Button
                  title='Previous'
                  fullWidth
                  onPress={handlePreviousStep}
                />
              </Col>

              <Col xs={12} md={4}>
                <Button fullWidth title='Next' onPress={handleNextStep} />
              </Col>
              <Col xs={12} md={4}>
                <Button
                  fullWidth
                  title='Save'
                  disabled={
                    input.partNumber === '' &&
                    input.nomenclature === '' &&
                    input.serialNumber === ''
                  }
                  onPress={handleSubmit}
                />
              </Col>
            </Row>
          </>
        );
      case 4:
        return (
          <>
            <Row>
              <Col xs={12} md={2} style={{ justifyContent: 'center' }}>
                <Switch
                  label='Calibration'
                  value={input.calibrationRequired}
                  onValueChange={(value) => {
                    if (value) {
                      setToolsInputAction({
                        nextCalibrationTestDueDate: moment()
                          .add(364, 'days')
                          .format('MM-DD-YYYY'),
                      });
                    } else {
                      setToolsInputAction({
                        nextCalibrationTestDueDate: '',
                      });
                    }
                    setToolsInputAction({ calibrationRequired: value });
                  }}
                  disabled={!isEditable}
                />
              </Col>
              <Col xs={12} md={4}>
                <Input
                  placeholder='Type in Next Calibration'
                  label='Next Calibration'
                  value={
                    input.nextCalibrationTestDueDate ==
                    '1900-01-01T00:00:00.000Z'
                      ? ''
                      : !input.nextCalibrationTestDueDate
                        ? ''
                        : moment
                            .utc(input.nextCalibrationTestDueDate)
                            .format('MM/DD/YYYY')
                  }
                  onChangeText={(item) =>
                    setToolsInputAction({
                      nextCalibrationTestDueDate: item.value,
                    })
                  }
                  editable={isEditable}
                  onClick={() => {
                    if (isEditable) {
                      setNextCalibrationCalendarVisible(
                        !nextCalibrationDateCalendarVisible
                      );
                    }
                  }}
                  rightIcon={
                    <Ionicons name='calendar' size={20} color='#00205b' />
                  }
                />
              </Col>
              <Col xs={12} md={2} style={{ justifyContent: 'center' }}>
                <Switch
                  label='Load Test'
                  value={input.loadTestRequired}
                  onValueChange={(value) => {
                    if (value) {
                      setToolsInputAction({
                        loadTestDueDate: moment()
                          .add(364, 'days')
                          .format('MM-DD-YYYY'),
                      });
                    } else {
                      setToolsInputAction({
                        loadTestDueDate: '',
                      });
                    }
                    setToolsInputAction({ loadTestRequired: value });
                  }}
                  disabled={!isEditable}
                />
              </Col>
              <Col xs={12} md={4}>
                <Input
                  placeholder='Type in Load Test Date'
                  label='Load Test Date'
                  value={
                    input.loadTestDueDate == '1900-01-01T00:00:00.000Z'
                      ? ''
                      : !input.loadTestDueDate
                        ? ''
                        : moment.utc(input.loadTestDueDate).format('MM/DD/YYYY')
                  }
                  onChangeText={(item) =>
                    setToolsInputAction({ loadTestDueDate: item.value })
                  }
                  editable={isEditable}
                  onClick={() => {
                    if (isEditable) {
                      setLoadTestDatePickerActiveAction(
                        !loadTestDatePickerActive
                      );
                    }
                  }}
                  rightIcon={
                    <Ionicons name='calendar' size={20} color='#00205b' />
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={2} style={{ justifyContent: 'center' }}>
                <Switch
                  label='PMI'
                  value={input.pmirequired}
                  onValueChange={(value) => {
                    if (value) {
                      setToolsInputAction({
                        nextPMIDate: moment()
                          .add(364, 'days')
                          .format('MM-DD-YYYY'),
                      });
                    } else {
                      setToolsInputAction({
                        nextPMIDate: '',
                      });
                    }
                    setToolsInputAction({ pmirequired: value });
                  }}
                  disabled={!isEditable}
                />
              </Col>
              <Col xs={12} md={4}>
                <Input
                  placeholder='Type in Next PMI'
                  label='Next PMI'
                  value={
                    input.nextPMIDate == '1900-01-01T00:00:00.000Z'
                      ? ''
                      : !input.nextPMIDate
                        ? ''
                        : moment.utc(input.nextPMIDate).format('MM/DD/YYYY')
                  }
                  onChangeText={(nextPMIDate) =>
                    setToolsInputAction({ nextPMIDate })
                  }
                  onClick={() => {
                    if (isEditable) {
                      setNextPMICalendarVisible(!nextPMICalendarVisible);
                    }
                  }}
                  rightIcon={
                    <Ionicons name='calendar' size={20} color='#00205b' />
                  }
                  editable={isEditable}
                />
              </Col>
              <Col xs={12} md={2} style={{ justifyContent: 'center' }}>
                <Switch
                  label='Pressure'
                  value={input.pressuretestRequired}
                  onValueChange={(value) => {
                    if (value) {
                      setToolsInputAction({
                        nextpressuretestDueDate: moment()
                          .add(364, 'days')
                          .format('MM-DD-YYYY'),
                      });
                    } else {
                      setToolsInputAction({
                        nextpressuretestDueDate: '',
                      });
                    }
                    setToolsInputAction({ pressuretestRequired: value });
                  }}
                  disabled={!isEditable}
                />
              </Col>
              <Col xs={12} md={4}>
                <Input
                  placeholder='Type in Pressure Test Date'
                  label='Pressure Test Date'
                  value={
                    input.nextpressuretestDueDate == '1900-01-01T00:00:00.000Z'
                      ? ''
                      : !input.nextpressuretestDueDate
                        ? ''
                        : moment
                            .utc(input.nextpressuretestDueDate)
                            .format('MM/DD/YYYY')
                  }
                  onChangeText={(item) =>
                    setToolsInputAction({ nextpressuretestDueDate: item.value })
                  }
                  editable={isEditable}
                  onClick={() => {
                    if (isEditable) {
                      setPressureTestCalendarVisible(
                        !pressureTestCalendarVisible
                      );
                    }
                  }}
                  rightIcon={
                    <Ionicons name='calendar' size={20} color='#00205b' />
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={2} style={{ justifyContent: 'center' }}>
                <Switch
                  label='Cyclic'
                  value={input.cyclictestRequired}
                  onValueChange={(value) => {
                    if (value) {
                      setToolsInputAction({
                        nextcyclictestDueDate: moment()
                          .add(364, 'days')
                          .format('MM-DD-YYYY'),
                      });
                    } else {
                      setToolsInputAction({
                        nextcyclictestDueDate: '',
                      });
                    }
                    setToolsInputAction({ cyclictestRequired: value });
                  }}
                  disabled={!isEditable}
                />
              </Col>
              <Col xs={12} md={4}>
                <Input
                  placeholder='Type in Cyclic Test Date'
                  label='Cyclic Test Date'
                  value={
                    input.nextcyclictestDueDate == '1900-01-01T00:00:00.000Z'
                      ? ''
                      : !input.nextcyclictestDueDate
                        ? ''
                        : moment
                            .utc(input.nextcyclictestDueDate)
                            .format('MM/DD/YYYY')
                  }
                  onChangeText={(item) =>
                    setToolsInputAction({ nextcyclictestDueDate: item.value })
                  }
                  editable={isEditable}
                  onClick={() => {
                    if (isEditable) {
                      setCyclicTestCalendarVisible(!cyclicTestCalendarVisible);
                    }
                  }}
                  rightIcon={
                    <Ionicons name='calendar' size={20} color='#00205b' />
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Input
                  editable={isEditable}
                  placeholder='Type in Field Tool Status'
                  label='Field Tool Status'
                  value={input.statusDescription}
                  onSelect={(toolStatus) =>
                    setToolsInputAction({
                      toolStatusCode: toolStatus.statusCode.trim(),
                      statusDescription: toolStatus.statusDescription.trim(),
                    })
                  }
                  selectedItem={(item) => item.statusDescription}
                  rowTextForSelection={(item) => item.statusDescription}
                  dropdown
                  dropdownChoices={searchResults.filter((item) => {
                    return item.statusDescription !== 'Unserviceable';
                  })}
                />
              </Col>
              <Col xs={12} md={6}>
                <Input
                  editable={auth.user.tenantId == 1 ? isEditable : false}
                  placeholder='Type in OEM Status'
                  label='OEM Status'
                  value={input.status}
                  onChangeText={(status) =>
                    setToolsInputAction({
                      status: status.trim(),
                    })
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Input
                  editable={isEditable}
                  placeholder='Type in Checkouts'
                  label='Checkouts'
                  value={input.checkOuts}
                  onChangeText={(checkOuts) =>
                    setToolsInputAction({ checkOuts })
                  }
                  number
                />
              </Col>
              <Col xs={12} md={6}>
                <Input
                  editable={isEditable}
                  placeholder='Type in Rhinestahl Status'
                  label='Rhinestahl Status'
                  value={input.toolStatus}
                  onChangeText={(toolStatus) =>
                    setToolsInputAction({ toolStatus })
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12}>
                <Input
                  placeholder='Type in Comments'
                  label='Comments'
                  value={input.comments}
                  onChangeText={(comments) => setToolsInputAction({ comments })}
                  editable={isEditable}
                  multiline
                  style={{ height: 200 }}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={3} style={{ justifyContent: 'center' }}>
                <Switch
                  label='Active'
                  value={input.activeFlag == 'Y' ? true : false}
                  onValueChange={(activeFlag) =>
                    setToolsInputAction({ activeFlag: activeFlag ? 'Y' : 'N' })
                  }
                  disabled={!isEditable}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={3}>
                <Button.EditTool />
              </Col>
              <Col xs={12} md={3}>
                <Button
                  title='Previous'
                  fullWidth
                  onPress={handlePreviousStep}
                />
              </Col>
              <Col xs={12} md={3}>
                <Button fullWidth title='Next' onPress={handleNextStep} />
              </Col>
              <Col xs={12} md={3}>
                <Button
                  fullWidth
                  title='Save'
                  disabled={
                    input.partNumber === '' ||
                    input.nomenclature === '' ||
                    input.serialNumber === '' ||
                    !isEditable
                  }
                  onPress={handleSubmit}
                />
              </Col>
            </Row>
          </>
        );
      case 5:
        return (
          <>
            <Row>
              <Col xs={12} md={2}>
                <Button
                  title='Add Inspection'
                  fullWidth
                  onPress={() => {
                    setInspectionsInputAction({ id: null });
                    setInspectionsEditAction(false);
                    setInspectionsShowModalAction(true);
                  }}
                  color={'#00205b'}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <List.Inspections
                  onRemove={(typeId) => {
                    var newInspections = inspections.list.filter(
                      (x) => x.inspectionTypeId !== typeId
                    );
                    setInspectionsListAction([...newInspections]);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={4}>
                <Button
                  title='Previous'
                  fullWidth
                  onPress={handlePreviousStep}
                />
              </Col>
              <Col xs={12} md={4}>
                <Button fullWidth title='Next' onPress={handleNextStep} />
              </Col>
              <Col xs={12} md={4}>
                <Button
                  fullWidth
                  title='Save'
                  disabled={
                    input.partNumber === '' &&
                    input.nomenclature === '' &&
                    input.serialNumber === ''
                  }
                  onPress={handleSubmit}
                />
              </Col>
            </Row>
          </>
        );
      case 6:
        return (
          <>
            <Button
              title='Add Document'
              onPress={async () => {
                const document = await DocumentPicker.getDocumentAsync({});
                if (
                  document.size > (process.env.MAX_UPLOAD_FILE_SIZE || 3000000)
                ) {
                  setAlertThunk({
                    type: 'error',
                    message: 'Files larger than 5.1MB are not allowed.',
                  });
                } else {
                  const extra = {
                    ...document,
                    type: 'extra',
                    notes: document.name,
                    id: null,
                    toolid: null,
                  };
                  setDocumentListAction([...documents, extra]);
                  setExtraDocumentListAction([
                    ...extraDocuments,
                    {
                      id: null,
                      notes: document.name,
                      tenantId: tenant.input.id,
                      mimeType: document.mimeType,
                      type: 'extra',
                      body: document.uri,
                      toolid: null,
                    },
                  ]);
                }
              }}
            />
            <Gap />
            <CustomModal.DocImage />
            <List.Documents />
            <Gap />
            <Row>
              <Col xs={12} md={6}>
                <Button
                  title='Previous'
                  fullWidth
                  onPress={handlePreviousStep}
                />
              </Col>

              <Col xs={12} md={6}>
                <Button
                  fullWidth
                  title='Save'
                  disabled={
                    input.partNumber === '' &&
                    input.nomenclature === '' &&
                    input.serialNumber === ''
                  }
                  onPress={handleSubmit}
                />
              </Col>
            </Row>
          </>
        );
    }
  };

  const renderStepIndicators = () => {
    const steps = [
      'Summary',
      'Location',
      'Detail',
      'Tool Care',
      'Inspection',
      'Documents',
    ];

    return steps.map((step, index) => (
      <TouchableOpacity
        key={index}
        onPress={() => setCurrentStep(index + 1)}
        style={[
          styles.stepIndicator,
          currentStep === index + 1 && styles.currentStepIndicator,
        ]}
      >
        <Text
          key={index}
          style={[
            styles.stepIndicator,
            currentStep === index + 1 && styles.currentStepIndicator,
          ]}
        >
          {step}
        </Text>
      </TouchableOpacity>
    ));
  };

  const handleSubmit = async () => {
    await upsertToolsThunk({
      navigation: props.navigation,
      input,
      message: 'Tool created successfully!',
    });

    if (extraDocuments && extraDocuments.length > 0) {
      for (const doc of extraDocuments) {
        upsertExtraDocumentThunk({
          navigation: props.navigation,
          input: { ...doc },
          message: 'Document Upserted',
        });
      }
    }

    if (inspections.list.length > 0) {
      for (const inspection of inspections.list) {
        upsertInspectionsThunk({ input: inspection });
      }
    }
  };

  return (
    <Card style={styles.container}>
      <ScrollView
        horizontal
        contentContainerStyle={styles.stepIndicatorsContainer}
      >
        {renderStepIndicators()}
      </ScrollView>
      <Divider />
      <Gap />
      {renderFormStep()}
    </Card>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
  },
  stepIndicatorsContainer: {
    flexDirection: 'row',
    alignSelf: 'center',
    marginBottom: 16,
    width: '100%',
  },
  stepIndicator: {
    fontFamily: 'Barlow_600SemiBold',
    flex: 1,
    fontSize: 18,
    textAlign: 'center',
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 8,
    marginRight: 8,
  },
  currentStepIndicator: {
    backgroundColor: '#e8e9ea',
  },
  stepText: {
    fontSize: 20,
    marginBottom: 16,
  },
});

export default AddToolStepForm;
