import { useStoreActions, useStoreState } from 'easy-peasy';
import { useEffect, useState } from 'react';

export default function useToolTransfer() {
  const [fromLocations, setFromLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [storageOptions, setStorageOptions] = useState([]);
  const [tenantOptions, setTenantOptions] = useState([]);
  const [toLocations, setToLocations] = useState([]);
  const [toolOptions, setToolOptions] = useState([]);
  const [tools, setTools] = useState([]);
  const [toolStatusOptions, setToolStatusOptions] = useState([]);
  const [transferInput, setInput] = useState({
    id: null,
    status: 'New',
    amount: null,
    trackingnumber: null,
  });

  const {
    tenant: { input },
  } = useStoreState((state) => state);

  const {
    getLocationThunk,
    getTenantOptionsThunk,
    getToolsByLocationThunk,
    getToolTransfersThunk,
    requestToolTransferThunk,
    sendMessageThunk,
    removeToolTransferThunk,
    upsertToolTransferThunk,
    upsertToolThunk,
    getStorageLocationThunk,
    getToolStatusesThunk,
  } = useStoreActions((actions) => actions);

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      await getTenantOptions();
      await getToolTransfersThunk();
      setLoading(false);
    };

    loadData();
  }, []);

  const getDetails = (tenantId, locaiontId, toolId, locations) => {
    let location = locations.find((item) => item.value === locaiontId);
    let tenant = tenantOptions.find((item) => item.value === tenantId);
    let tool = tools.find((item) => item.id === toolId);

    return { location, tenant, tool };
  };

  const approveToolTransfer = async () => {
    setLoading(true);
    await upsertToolTransferThunk({
      ...transferInput,
      status: 'In Transit',
      tenantid: transferInput.totenantid,
    });
    let { location, tenant, tool } = getDetails(
      transferInput.totenantid,
      transferInput.totoollocationid,
      transferInput.toolid,
      toLocations
    );
    tool.toolStatusCode = 'IT';
    await upsertToolThunk(tool);
    await sendMessageThunk({
      id: null,
      tenantId: transferInput.totenantid,
      messagepreview: `Tool ${tool.partNumber} (${tool.serialNumber}) has been approved`,
      message: `Tool ${tool.partNumber} (${tool.serialNumber}) is in transit to ${tenant.label}'s ${location.label} facility, with tracking number of ${transferInput.trackingnumber}.`,
      comments: '',
      messagestatusID: 1,
      toolstatus: tool.status,
      partNumber: tool.partNumber,
    });
    await getToolTransfersThunk();
    clearTransfer();
    setLoading(false);
  };

  function clearTransfer() {
    setInput({
      id: null,
      status: 'New',
      amount: null,
      trackingnumber: null,
    });
    setTools([]);
    setToLocations([]);
    setFromLocations([]);
  }

  const getTenantOptions = async () => {
    const tenants = await getTenantOptionsThunk(
      input.parentTenantId || input.id
    );
    setTenantOptions(
      tenants.map((tenant) => {
        return { label: tenant.name, value: tenant.id };
      })
    );
  };

  const getTools = async ({ tenantid, userlocationid }) => {
    const toolsResponse = await getToolsByLocationThunk({
      tenantId: tenantid,
      userlocationid,
    });
    setTools([...toolsResponse]);
    setToolOptions(
      toolsResponse?.map((tool) => {
        return {
          label: `${tool.partNumber} (${tool.serialNumber})`,
          value: tool.id,
        };
      })
    );
  };

  const receiveTransfer = async () => {
    setLoading(true);
    let { location, tenant, tool } = getDetails(
      transferInput.totenantid,
      transferInput.totoollocationid,
      transferInput.toolid,
      toLocations
    );
    tool.storageLocationId = transferInput.toStorageLocation;
    tool.toolStatusCode = transferInput.toolStatus;
    tool.tenantId = transferInput.totenantid;
    await upsertToolThunk(tool);
    await removeToolTransferThunk(transferInput);
    await sendMessageThunk({
      id: null,
      tenantId: transferInput.fromtenantid,
      messagepreview: `Tool transfer for ${tool.partNumber} (${tool.serialNumber}) has been received`,
      message: `${tool.partNumber} (${tool.serialNumber}) has been received at ${tenant.label}'s ${location.label} facility.`,
      comments: '',
      messagestatusID: 1,
      toolstatus: tool.status,
      partNumber: tool.partNumber,
    });
    await getToolTransfersThunk();
    clearTransfer();
    setLoading(false);
  };

  const rejectTransfer = async () => {
    setLoading(true);
    let { location, tenant, tool } = getDetails(
      transferInput.fromtenantid,
      transferInput.fromtoollocationid,
      transferInput.toolid,
      fromLocations
    );
    await removeToolTransferThunk(transferInput);
    await sendMessageThunk({
      id: null,
      tenantId: transferInput.totenantid,
      messagepreview: `Tool transfer for ${tool.partNumber} (${tool.serialNumber}) has been rejected`,
      message: `${tool.partNumber} (${tool.serialNumber}) will not be transferred to ${tenant.label}'s ${location.label} facility.`,
      comments: '',
      messagestatusID: 1,
      toolstatus: tool.status,
      partNumber: tool.partNumber,
    });
    await getToolTransfersThunk();
    clearTransfer();
    setLoading(false);
  };

  const requestToolTransfer = async () => {
    setLoading(true);
    let { location, tenant, tool } = getDetails(
      transferInput.totenantid,
      transferInput.totoollocationid,
      transferInput.toolid,
      toLocations
    );
    const request = await requestToolTransferThunk({
      ...transferInput,
      status: 'Pending',
    });
    await sendMessageThunk({
      id: null,
      tenantId: transferInput.fromtenantid,
      messagepreview: `Tool ${tool.partNumber} (${tool.serialNumber}) has been requested by ${request?.createdBy}`,
      message: `${request.createdBy} has requested that ${tool.partNumber} (${tool.serialNumber}) be transferred to ${tenant.label}'s ${location.label} facility.`,
      comments: '',
      messagestatusID: 1,
      toolstatus: tool.status,
      partNumber: tool.partNumber,
    });
    await getToolTransfersThunk();
    clearTransfer();
    setLoading(false);
  };

  const selectTenant = async (selectedValue) => {
    const locations = await getLocationThunk(
      selectedValue.fromtenantid || selectedValue.totenantid
    );

    const options = locations?.map((location) => {
      return {
        label: location.name,
        value: location.id,
      };
    });

    if (selectedValue.fromtenantid) {
      setInput({
        ...transferInput,
        ...selectedValue,
        tenantid: selectedValue.fromtenantid,
      });
      setFromLocations(options);
    } else {
      setInput({
        ...transferInput,
        ...selectedValue,
      });
      setToLocations(options);
    }
  };

  const selectToolTransfer = async (selectedTransfer) => {
    setLoading(true);
    setInput({ ...selectedTransfer });
    const [toLocations, fromLocations, tools] = await Promise.all([
      getLocationThunk(selectedTransfer.totenantid),
      getLocationThunk(selectedTransfer.fromtenantid),
      getTools({
        tenantid: selectedTransfer.tenantid,
        userlocationid: selectedTransfer.fromtoollocationid,
      }),
    ]);

    if (selectedTransfer.status === 'In Transit') {
      const [storageLocations, toolStatuses] = await Promise.all([
        getStorageLocationThunk(selectedTransfer.totenantid),
        getToolStatusesThunk(),
      ]);

      setStorageOptions(
        storageLocations
          .sort((a, b) => a.name.localeCompare(b.name))
          .filter((item) => {
            return (
              item.active !== false &&
              selectedTransfer.totoollocationid === item.tenantlocationid
            );
          })
          .map((item) => {
            return {
              label: item.name,
              value: item.id,
            };
          })
      );

      setToolStatusOptions(
        toolStatuses
          .filter((item) => {
            return item.statusDescription !== 'Unserviceable';
          })
          .map((item) => {
            return {
              label: item.statusDescription,
              value: item.statusCode.trim(),
            };
          })
      );
    }

    setToLocations([
      ...toLocations.map((location) => {
        return {
          label: location.name,
          value: location.id,
        };
      }),
    ]);
    setFromLocations([
      ...fromLocations.map((location) => {
        return {
          label: location.name,
          value: location.id,
        };
      }),
    ]);

    setLoading(false);
  };

  return {
    fromLocations,
    loading,
    storageOptions,
    tenantOptions,
    toLocations,
    toolOptions,
    toolStatusOptions,
    transferInput,
    approveToolTransfer,
    clearTransfer,
    getTools,
    receiveTransfer,
    rejectTransfer,
    requestToolTransfer,
    selectTenant,
    selectToolTransfer,
    setInput,
  };
}
